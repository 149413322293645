import React, { useEffect, useState } from "react";
import QRCode from "qrcode";

const QRCodeGenerator = ({ transactionId }) => {
  const [qrImage, setQrImage] = useState(null);

  useEffect(() => {
    if (transactionId) {
      QRCode.toDataURL(transactionId, {
        width: 256,
        margin: 2,
      })
        .then((url) => setQrImage(url))
        .catch((err) => console.error("QR Code generation error:", err));
    }
  }, [transactionId]);

  return (
    <div>
      {qrImage ? (
        <img
          src={qrImage}
          alt="QR Code"
          style={{
            padding: "10px",
            border: "1px solid #969696",
            borderRadius: "20px",
            backgroundColor: "#fff",
          }}
        />
      ) : (
        <p>Generating QR code...</p>
      )}
    </div>
  );
};

export default QRCodeGenerator;
